import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

export const HorizontalBar = ({ size, style, color }) => {
  const styles = {
    main: css`
      border-top: ${size}px ${style} ${color};
    `
  };

  return <div css={styles.main} />;
};

HorizontalBar.defaultProps = {
  size: 2,
  style: 'solid',
  color: '#000'
};

HorizontalBar.propTypes = {
  size: PropTypes.number,
  style: PropTypes.string,
  color: PropTypes.string
};
