import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { List } from '../atoms/List';
import { theme } from '../../../domain/theme';

const styles = {
  container: css`
    text-align: center;
    overflow: hidden;
  `,
  ul: css`
    margin: 0 auto;
    display: inline-flex;
    flex-wrap: wrap;
  `,
  li: active => css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('base')};
    text-transform: uppercase;
    display: block;
    padding: ${theme.spacing('xs2')};
    line-height: 1;
    min-width: 50px;
    height: 50px;
    text-align: center;
    background-color: ${active ? '#fc0' : 'transparent'};
    ${active ? 'border-bottom: 5px solid #000;' : null}
    :hover {
      background-color: #fc0;
    }
    transition: background-color 0.3s ease;
    a {
      display: block;
      padding: ${theme.spacing('xs2')};
      color: #333;
      :hover {
        color: #000;
      }
    }
  `
};
export const HorizontalMenu = ({ items }) => (
  <List
    items={items}
    containerStyle={styles.container}
    ulStyle={styles.ul}
    liStyle={styles.li}
  />
);

HorizontalMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};
