import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf, t } from '../../../../domain/services/configService';
import { theme } from '../../../../domain/theme';
import { Headline } from '../../atoms/Headline';
import { ContainerMain } from '../../atoms/ContainerMain';
import { HorizontalBar } from '../../atoms/HorizontalBar';
import { HorizontalMenu } from '../../molecules/HorizontalMenu';
import { SmartLink } from '../../../SmartLink';
import { InlineSearch } from '../../organisms/InlineSearch';
import { apiClientService } from '../../../../domain/services/apiClientService';

// Since this is a template, we can customize its spacings.
// Note that this strategy must be confirmed true when
// developing other pieces of atomic design, so it could change.
const styles = {
  main: css`
    margin-top: ${theme.spacing('xs')};
    a.author::after,
    a.photographer::after,
    a.explorer::after {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: sub;
    }
    a.author::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-label='author' style='fill:%23333;color:%23333' viewBox='0 0 32 32'%3E%3Cpath d='M19.75,15.67a6,6,0,1,0-7.51,0A11,11,0,0,0,5,26v1H27V26A11,11,0,0,0,19.75,15.67ZM12,11a4,4,0,1,1,4,4A4,4,0,0,1,12,11ZM7.06,25a9,9,0,0,1,17.89,0Z'%3E%3C/path%3E%3C/svg%3E");
    }
    a.photographer::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-label='photographer' style='fill:%23333;color:%23333' viewBox='-2 -2 26 26'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M23.4 20.7333V5.02667H17.8885L15.8615 3H8.13853L6.11203 5.02667H0.599998V20.7333H23.4ZM21.88 19.2133H2.12V6.54667H6.74147L8.76797 4.52H15.2325L17.2585 6.54667H21.88V19.2133ZM12 7.05333C9.06184 7.05333 6.68 9.43518 6.68 12.3733C6.68 15.3115 9.06184 17.6933 12 17.6933C14.9382 17.6933 17.32 15.3115 17.32 12.3733C17.32 9.43518 14.9382 7.05333 12 7.05333ZM12 8.57333C14.0987 8.57333 15.8 10.2747 15.8 12.3733C15.8 14.472 14.0987 16.1733 12 16.1733C9.90132 16.1733 8.2 14.472 8.2 12.3733C8.2 10.2747 9.90132 8.57333 12 8.57333ZM4.4 10.3467C5.23947 10.3467 5.92 9.66614 5.92 8.82667C5.92 7.98719 5.23947 7.30667 4.4 7.30667C3.56053 7.30667 2.88 7.98719 2.88 8.82667C2.88 9.66614 3.56053 10.3467 4.4 10.3467Z' fill='%23333333'%3E%3C/path%3E%3C/svg%3E");
      margin-left: 5px;
    }
    a.explorer::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='fill:%23333;color:%23333' viewBox='0 0 512 512' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M208.938,331.094l11.875,29.688c-11.5,4.594-22.969,8.094-34.156,10.438l-6.531-31.313 C189.5,337.938,199.188,334.969,208.938,331.094z M113.719,369.438c11.719,3.375,24.063,5.188,36.688,5.375l0.5-32 c-9.813-0.156-19.344-1.531-28.344-4.125L113.719,369.438z M365.688,313.594c9.219,1.813,18.219,4.969,26.75,9.375l14.688-28.438 c-11.25-5.781-23.094-9.938-35.25-12.313L365.688,313.594z M298.938,287.344l9.563,30.5c9.781-3.063,19.438-5,28.75-5.75 l-2.563-31.875C323,281.156,310.969,283.563,298.938,287.344z M247.563,311.406c-3.594,2.25-7.125,4.313-10.594,6.25l15.563,27.938 c3.906-2.188,7.906-4.5,11.906-7c5.5-3.406,10.875-6.438,16.156-9.125l-14.531-28.5C260.063,304.031,253.875,307.5,247.563,311.406z M336,112c0,13.063-3.125,25.438-8.688,36.313L256,288c0,0-71.875-140.844-72.156-141.438C178.813,136.125,176,124.375,176,112 c0-44.188,35.813-80,80-80S336,67.813,336,112z M304,112c0-26.5-21.5-48-48-48s-48,21.5-48,48s21.5,48,48,48S304,138.5,304,112z M416,192h-75.063l-16.344,32h68.344l34.531,103.625l-11.031,12.188c11.75,10.625,17.844,20.406,17.875,20.438l3.375-2.031 L467.594,448H44.375l32.063-96.094c1.125,0.781,2,1.531,3.25,2.313l17-27.063c-3.813-2.406-6.938-4.781-9.688-7L119.063,224h68.375 c-6.781-13.25-12.125-23.719-16.344-32H96L0,480h512L416,192z'/%3E%3C/svg%3E%0A");
      margin-left: 5px;
    }
  `,
  itemsContainer: css`
    ${theme.mq.tablet} {
      column-count: 2;
      column-gap: ${theme.spacing('base')};
    }
    ${theme.mq.small_desktop} {
      column-count: 3;
    }
    ${theme.mq.large_desktop} {
      column-count: 4;
    }
  `,
  itemsUl: css`
    list-style: none;
    a {
      display: inline-block;
      margin: ${theme.spacing.parse('$xs2 0')};
      color: #333;
      :hover {
        color: #000;
        text-decoration: underline;
      }
    }
    svg {
      vertical-align: sub;
    }
  `
};

const getClassName = item => {
  let className = item.bundle || '';
  if (item.url.startsWith(`/${t('explorer')}/`)) {
    className = 'explorer';
  }
  return className;
};

export const TaxonomyItemTemplate = ({ content }) => {
  const menuItems = content.initialChars.map(initialChar => ({
    title: initialChar,
    listLabel: initialChar,
    url: `/${t('topic')}/${initialChar}`,
    active: initialChar === content.char
  }));
  const inlineSearchResolver = async q => {
    const { response } = await apiClientService.getJson(
      `${conf.searchEndpoint}?handler=list&type=hub_page,author,photographer&limit=20&q=${q}`
    );
    const results = response?.results.map(result => {
      return {
        ...result,
        listLabel: result.title.replace(/q/gi, '<strong>$&</strong>')
      };
    });
    return results;
  };
  return (
    <div css={styles.main}>
      <ContainerMain>
        <Headline>{t('Topics, authors and photographers')}</Headline>
        <InlineSearch resolver={inlineSearchResolver} />
        <HorizontalMenu items={menuItems} />
        <HorizontalBar />
        <div css={styles.itemsContainer}>
          <ul css={styles.itemsUl}>
            {content.items.map(item => (
              <li key={`${item.url}-${item.title}`}>
                <SmartLink
                  to={item.url}
                  title={item.title}
                  className={getClassName(item)}
                >
                  {item.title.toUpperCase()}
                </SmartLink>
              </li>
            ))}
          </ul>
        </div>
      </ContainerMain>
    </div>
  );
};

TaxonomyItemTemplate.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
